import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//条件查询结算方列表
export function findSettlementInfoByPage(params) {
    return request.get('/settlement/findSettlementInfoByPage?' + objectToQueryString(params))
}

//根据结算方id查询停车场列表
export function findParkingBySettlementId(id, pageSize, pageNo) {
    return request.get('/settlement/findParkingBySettlementId?id=' + id + '&pageSize=' + pageSize + '&pageNo=' + pageNo)
}

//根据结算方id获取结算方联系人列表
export function findContactInformationBySettlementId(id) {
    return request.get('/settlement/findContactInformationBySettlementId?id=' + id)
}

//根据结算方id获取企业认证信息
export function findEnterpriseCertification(id) {
    return request.get('/settlement/findEnterpriseCertification?id=' + id)
}

//根据结算方id查询结算方营业信息
export function findBusinessInformation(id) {
    return request.get('/settlement/findBusinessInformation?id=' + id)
}

//根据id获取结算方基本信息
export function findBaseSettlementInfoById(id) {
    return request.get('/settlement/findBaseSettlementInfoById?id=' + id)
}

//删除结算方关联停车场
export function deleteSettlementParking(body) {
    return request.post('/settlement/deleteSettlementParking', body)
}

//删除结算方
export function deleteSettlementById(id) {
    return request.get('/settlement/deleteSettlementById?id=' + id)
}

//保存企业认证信息
export function createOrUpdateEnterpriseCertification(body) {
    return request.post('/settlement/createOrUpdateEnterpriseCertification', body)
}

//创建或更新营业信息
export function createOrUpdateBusinessInformation(body) {
    return request.post('/settlement/createOrUpdateBusinessInformation', body)
}

//保存或更新结算方基本信息
export function createOrUpdateBaseSettlementInfo(body) {
    return request.post('/settlement/createOrUpdateBaseSettlementInfo', body)
}

//保存联系人信息
export function createContactInformation(body) {
    return request.post('/settlement/createContactInformation', body)
}

//结算方添加停车场
export function addSettlementParking(body) {
    return request.post('/settlement/addSettlementParking', body)
}

// 提现申请
export function doCashOutApply(params) {
    return request.get('/wallet/withdrawApply?' + objectToQueryString(params));
}

