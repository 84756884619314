<template>
  <div class="page">
    <div class="tabs-wrap">
      <el-tabs v-model="currentTab" v-loading="isLoading" type="border-card">
        <el-tab-pane label="结算方信息" name="settlement">
          <el-descriptions :column="2" border>
            <el-descriptions-item>
              <template slot="label"> 结算方编号</template>
              {{ baseSettlementInfo.code }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 注册时间</template>
              {{ baseSettlementInfo.registrationTime }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 结算方名称</template>
              {{ baseSettlementInfo.settlementName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 城区</template>
              {{ baseSettlementInfo.regionName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 主体类型</template>
              {{ subjectType[baseSettlementInfo.subjectType] }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 详细地址</template>
              {{ baseSettlementInfo.address }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 登录手机号</template>
              {{ baseSettlementInfo.tel }}
            </el-descriptions-item>
            <!-- <el-descriptions-item>
              <template slot="label"> 关联商户 </template>
              {{ baseSettlementInfo.merchantId }}
            </el-descriptions-item> -->
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="营业信息" name="business">
          <easy-card title="企业资质">
            <el-descriptions :column="2" border>
              <el-descriptions-item>
                <template slot="label"> 营业执照编号</template>
                {{ businessInfo.businessLicenseNumber }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 税号</template>
                {{ businessInfo.taxId }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 营业执照图片</template>
                <PictureMerchant :attachId="businessInfo.businessLicenseAttachmentId" width="240px"/>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 法人身份证</template>
                <PictureMerchant :attachId="businessInfo.legalPersonId" width="240px"/>
              </el-descriptions-item>
            </el-descriptions>
          </easy-card>

          <easy-card title="开户信息">
            <el-descriptions :column="2" border>
              <el-descriptions-item>
                <template slot="label"> 开户行类型</template>
                {{ businessInfo.bankType === "1" ? "农业银行" : "其他银行" }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 银行总行名称</template>
                {{ businessInfo.bankHeadOfficeName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 银行总行行号</template>
                {{ businessInfo.bankHeadOfficeNumber }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 开户分行名称</template>
                {{ businessInfo.accountOpeningBranchName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 账号</template>
                {{ businessInfo.account }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 银行开户行</template>
                {{ businessInfo.bankAccountName }}
              </el-descriptions-item>
            </el-descriptions>
          </easy-card>

          <easy-card title="结算规则">
            <el-descriptions :column="2" border>
              <el-descriptions-item>
                <template slot="label"> 结算账期（天）</template>
                {{ businessInfo.accountPeriod }}
              </el-descriptions-item>
              <!-- <el-descriptions-item>
                <template slot="label"> 银联提现费率 </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 银联提现手续费 </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 首次结算时间 </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 服务费发票 </template>
              </el-descriptions-item> -->
            </el-descriptions>
          </easy-card>
        </el-tab-pane>
        <el-tab-pane label="企业认证信息" name="enterprise">
          <el-descriptions :column="2" border>
            <el-descriptions-item>
              <template slot="label"> 企业名称</template>
              {{ enterpriseCertificationInfo.enterpriseName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 统一社会信用代码</template>
              {{ enterpriseCertificationInfo.unifiedSocialCreditCode }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 法人姓名</template>
              {{ enterpriseCertificationInfo.legalPersonName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 身份证号码</template>
              {{ enterpriseCertificationInfo.idCard }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 法人手机号</template>
              {{ enterpriseCertificationInfo.legalPersonTel }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 开户行代码</template>
              {{ enterpriseCertificationInfo.bankCode }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 开户行支行</template>
              {{ enterpriseCertificationInfo.accountOpeningBranch }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 银行账户名称</template>
              {{ enterpriseCertificationInfo.bankAccountName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 银行账户号</template>
              {{ enterpriseCertificationInfo.bankAccountNumber }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 联系人姓名</template>
              {{ enterpriseCertificationInfo.contactName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 联系人电话</template>
              {{ enterpriseCertificationInfo.contactTel }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 联系人身份证号</template>
              {{ enterpriseCertificationInfo.contactIdCard }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 最后更新时间</template>
              {{ enterpriseCertificationInfo.lastUpdateTime }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"></template>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="联系人信息" name="contacts">
          <el-table
              :data="contactInfo"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="联系人姓名" prop="contactName">
            </el-table-column>
            <el-table-column label="联系方式" prop="contactDetails">
            </el-table-column>
            <el-table-column label="邮箱" prop="mailbox">
            </el-table-column>
            <el-table-column label="职务" prop="jobTitle"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="车场信息" name="parkinglot">
          <div class="op-box">
            <div class="op-content">
              <span>停车场:{{ parkingOtherData.parkingLotNumber }}</span>
              <span style="margin-left: 100px">泊位数量:{{ parkingOtherData.totalBerthsNumber }}</span>
            </div>
          </div>
          <el-table
              :data="parkingInfo"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="车场编号" prop="parkingLotNum">
            </el-table-column>
            <el-table-column label="车场名称" prop="parkingLotName">
            </el-table-column>
            <el-table-column label="城区" prop="regionName">
            </el-table-column>
            <el-table-column label="车场位置" prop="parkingLotLocation">
            </el-table-column>
            <el-table-column label="泊位数量" prop="berthsNumber">
            </el-table-column>
          </el-table>
          <div class="pagination-box">
            <el-pagination
                :current-page="pageNo"
                :page-size="pageSize"
                :page-sizes="[10, 15, 30, 50]"
                :total="total"
                background
                layout="sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-button class="back-button" type="text" @click="goBack"
      ><i class="el-icon-back"></i>返回
      </el-button
      >
    </div>
  </div>
</template>

<script>
import PictureMerchant from "@/components/PictureMerchant";
import {
  findBaseSettlementInfoById,
  findBusinessInformation,
  findContactInformationBySettlementId,
  findEnterpriseCertification,
  findParkingBySettlementId
} from "@/api/settlementManage";

export default {
  components: {
    PictureMerchant,
  },
  data() {
    return {
      id: "",
      isLoading: false,
      currentTab: "settlement",
      baseSettlementInfo: {}, //结算方信息
      subjectType: {
        1: "企业",
        2: "个体户",
        3: "个人",
      },
      businessInfo: {}, //营业信息
      enterpriseCertificationInfo: {}, //企业认证信息
      contactInfo: [], //联系人信息
      parkingInfo: [], //车场信息
      pageNo: 1,
      pageSize: 10,
      parkingOtherData: {},//其他车场信息
      total: 0,
    };
  },
  methods: {
    //返回上一页
    goBack() {
      this.$router.back();
    },
    //获取结算方信息
    getBaseSettlementInfo(id) {
      this.isLoading = true;
      findBaseSettlementInfoById(id).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.baseSettlementInfo = {
            code: returnObject.code,
            settlementName: returnObject.settlementName,
            regionId: returnObject.regionId,
            subjectType: returnObject.subjectType,
            address: returnObject.address,
            tel: returnObject.tel,
            merchantId: returnObject.merchantId,
            registrationTime: returnObject.registrationTime,
          };
        }
      });
    },

    //获取营业信息
    getBusinessInfo(id) {
      findBusinessInformation(id).then(res => {
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.businessInfo = {
            businessLicenseNumber: returnObject.qualification.businessLicenseNumber,
            taxId: returnObject.qualification.taxId,
            businessLicenseAttachmentId: returnObject.qualification.businessLicenseAttachmentId,
            legalPersonId: returnObject.qualification.legalPersonId,
            bankType: returnObject.cardInfoVo.bankType,
            bankHeadOfficeName: returnObject.cardInfoVo.bankHeadOfficeName,
            bankHeadOfficeNumber: returnObject.cardInfoVo.bankHeadOfficeNumber,
            accountOpeningBranchName: returnObject.cardInfoVo.accountOpeningBranchName,
            account: returnObject.cardInfoVo.account,
            bankAccountName: returnObject.cardInfoVo.bankAccountName,
            accountPeriod: (returnObject.accountPeriod) < 0 ? "" : returnObject.accountPeriod,
          };
        }
      })
    },

    //获取企业认证信息
    getEnterpriseCertificationInfo(id) {
      findEnterpriseCertification(id).then(res => {
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.enterpriseCertificationInfo = {
            enterpriseName: returnObject.enterpriseName,
            unifiedSocialCreditCode: returnObject.unifiedSocialCreditCode,
            legalPersonName: returnObject.legalPersonName,
            idCard: returnObject.idCard,
            legalPersonTel: returnObject.legalPersonTel,
            bankCode: returnObject.bankCode,
            accountOpeningBranch: returnObject.accountOpeningBranch,
            bankAccountName: returnObject.bankAccountName,
            bankAccountNumber: returnObject.bankAccountNumber,
            contactName: returnObject.contactName,
            contactTel: returnObject.contactTel,
            contactIdCard: returnObject.contactIdCard,
            lastUpdateTime: returnObject.lastUpdateTime,
          }
        }
      })
    },

    //获取联系人信息
    getContactInfo(id) {
      findContactInformationBySettlementId(id).then(res => {
        if (res.code === 30 && res.result) {
          this.contactInfo = res.returnObject;
        }
      })
    },

    //获取车场信息
    getParkingInfo() {
      const id = this.id;
      const pageNo = this.pageNo;
      const pageSize = this.pageSize;
      findParkingBySettlementId(id, pageSize, pageNo).then(res => {
        if (res.code === 30 && res.result) {
          this.parkingInfo = res.returnObject.list;
          this.parkingOtherData = {
            totalBerthsNumber: res.returnObject.totalBerthsNumber,
            parkingLotNumber: res.returnObject.parkingLotNumber,
          };
          this.total = res.returnObject.total;
        }
      })
    },

    //停车场分页查询
    handleSizeChange(e) {
      this.pageSize = e;
      this.getParkingInfo();
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.getParkingInfo();
    },
  },
  mounted() {
    const id = this.$route.query.id;
    this.id = id;
    this.getBaseSettlementInfo(id);
    this.getBusinessInfo(id);
    this.getEnterpriseCertificationInfo(id);
    this.getContactInfo(id);
    this.getParkingInfo();
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
