<template>
  <div class="picture-wrap">
    <img
        :src="attachmentURL"
        :style="{ 'max-width': this.width, height: this.height }"
    />
  </div>
</template>

<script>
import {getAttachmentURL} from "@/api/common";

export default {
  name: "xxd-picture-merchant",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "auto",
    },
    // 附件ID
    attachId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      attachmentURL: "",
    };
  },
  watch: {
    attachId(newVal) {
      if (newVal) {
        this.getAttachmentURL();
      }
    },
  },
  methods: {
    async getAttachmentURL() {
      if (!this.attachId) {
        return;
      }

      const res = await getAttachmentURL(this.attachId);
      if (res && res.code === 30 && res.result) {
        this.attachmentURL = res.returnObject;
      }
    },
  },
  mounted() {
    this.getAttachmentURL();
  },
};
</script>

<style lang="less" scoped>
</style>
